.nav-menu-items.active {
  display: none;
}
.nav-menu-items li {
  display: inline;
  list-style-type: none;
}
.nav-menu-items {
  margin-top: 12px;
}
.nav-menu {
  z-index: 1;
  display: flex;
  background-color: rgb(183, 247, 181);
  height: 53px;
  position: sticky;
  width: 100%;
  top: 0px;
  overflow-x: overlay;
}
.nav-menu.active {
  z-index: 1;
  display: flex;
  background-color: rgb(183, 247, 181);
  height: fit-content;
  position: sticky;
  width: fit-content;
  top: 0px;
}
.nav-text a:hover {
  background-color: #ffffff;
}
.nav-text a {
  text-decoration: none;
  text-align: center;
  justify-content: center;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  align-items: center;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  padding-left: 5px;
  padding-right: 5px;
}
.nav-text {
  justify-content: start;
  align-items: center;
  padding: 8px 8px 8px 8px;
  height: 60px;
}
.menu-bars {
  justify-content: center;
  text-align: center;
  color: black;
  min-height: 23px;
  width: 23px;
  margin: 15px;
}
